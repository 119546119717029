a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
  color: #000;
  letter-spacing: normal;
  font-size: 16px;
  font-family: 'Karla', sans-serif;
  line-height: 1.63;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.h1, .h2, .h3, .h4, .h5, h1, h2, h3, h4, h5 {
    display: block;
    color: inherit;
    font-family: 'Karla', sans-serif;
    font-weight: 800;
}
.address {
  border-bottom: 1px dashed #fff;
  cursor: pointer;
}
.pk .h1, .pk h1 {
  font-size: 36px;
  line-height: 1.22;
}

.pk .h2, .pk h2 {
  font-size: 30px;
  line-height: 1.33;
}

p, strong {
    color: #000;
    font-size: 20px;
    line-height: 1.25;
}

.h1+p, .h2+p, .h3+p, .h4+p, .h5+p, h1+p, h2+p, h3+p, h4+p, h5+p {
    margin-top: 10px;
}

.white {
    color: #fff;
}

a {
  text-decoration: none;
  -webkit-transition: .2s all;
  transition: .2s all;
  color: inherit;
  cursor: pointer;
}

ol, ul {
  list-style: none;
}

p {
  font-weight: bold;
  margin-top: 10px;
}

.pk hr {
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #fff;
}

.text-center {
    text-align: center;
}

.pk p.is-small {
  color: #232323;
  font-size: 16px;
  line-height: 1.56;
}

/* Container */
.pk-content {
  padding-right: 24px;
  padding-left: 24px;
  margin: 0 auto;
}

.pk-content-md {
  padding-right: 24px;
  padding-left: 24px;
}


.pk-mt-20 {
  margin-top: 20px;
}

.pk-mb-40 {
  margin-bottom: 40px;
}

.pk-mt-60 {
  margin-top: 60px;
}

.pk-mb-80 {
  margin-bottom: 80px;
}

.pk-mt-80 {
  margin-top: 80px;
}

.pk-pt-30 {
  padding-top: 30px;
}

.pk-pb-80 {
  padding-bottom: 80px;
}


/* Navbar */
.navbar {
  color: #fff;
  position: absolute;
  top: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  list-style: normal;
  z-index: 10;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

/* .navbar-desktop {
  display: none;
} */

.navbar-content {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 72px;
  margin: auto;
  max-width: 1170px;
  padding: 0 24px;
}

.navbar-home-link {
  margin-right: 30px;
}

.navbar-home-link img {
  max-width: 72px;
}

.navbar-login-options {
  -webkit-box-flex: 1;
  flex: 1;
}

.navbar-login-options {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.list-horizontal {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.navbar .list-horizontal {
  margin-top: 0;
}

.list-horizontal .list-option {
  margin-right: 25px;
}

.navbar .list-option {
  -webkit-transition: .2s all;
  transition: .2s all;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

.list-option a {
  -webkit-transition: none;
  transition: none;
}

.navbar-login-options .btn {
  -webkit-transform: translate(0);
  transform: translate(0);
}


/* Header */
.pk-header-wrapper {
  max-width: 100vw;
  overflow-x: hidden;
}

.pk-header {
  position: relative;
  padding: 0;
  background-color: #232323;
  color: #fff;
  overflow: hidden;
}

.pk-header-solid {
  border-radius: 0;
  background-color: #fff;
  color: #232323;
}

.pk-header-solid.is-dark {
  background-color: #e3212f;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0)), linear-gradient(to bottom, #ec1f26, #ec1f26);
}

.pk-header-solid.is-dark {
  padding-bottom: 40px;
}

.pk-header-content {
  /* 50 + 30 */
  padding-top: 80px;
  position: relative;
}

.pk-header-copy {
  padding: 60px 0 40px;
}

.pk-header-solid.is-dark h1, .pk-header-solid.is-dark p {
  color: #fff;
}

.pk-header-img-wrapper {
  margin: auto;
  padding-bottom: 60px;
  width: 290px;
  display: block;
}

.pk-header-solid .pk-header-img-wrapper {
  padding-bottom: 0;
}

.pk-header-img {
  display: block;
  width: 100%;
}

.pk-header-logos {
  margin: 45px 0 0;
  /* max-width: 316px; */
}

.pk-header-logos > span {
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 33px;
}

.pk-header-logos .pk-logo {
  padding-top: 23px;
}

.pk-header-logos .pk-logo-bar {
  -webkit-box-pack: justify;
  justify-content: flex-start;
}

.pk-header-logos .pk-logo-image, .pk-header-logos .pk-logo-link {
  margin-bottom: 20px;
  margin-right: 12px;
  width: 82px;
}

.pk-header-logos .pk-logo-image, .pk-header-logos .pk-logo-link:last-child {
  margin-right: 0;
}

.pk-header-logos .pk-logo-link > img {
  width: 100%;
}

.pk-header-solid .pk-logo-bar .pk-logo-image:nth-child(3), .pk-header-solid .pk-logo-bar .pk-logo-link:nth-child(3) {
  width: 64px;
}

.pk-header-pattern {
  position: absolute;
  left: 0;
  top: 95px;
  width: 100%;
  height: 26px;
  padding: 0 24px;
  z-index: 0;
}

.pk-header-pattern > img {
  -o-object-fit: cover;
  object-fit: cover;
  width: inherit;
  height: inherit;
}


/* Buttons */
.pk-btn-group {
  margin-top: 30px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.pk-btn-group .pk-btn:not(:first-child) {
  margin-top: 20px;
}

.pk-btn {
  display: inline-block;
  position: relative;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 800;
  outline: 0;
  cursor: pointer;
  font-family: 'Karla', sans-serif;
  line-height: normal;
  min-width: 230px;
  text-align: center;
  text-decoration: none;
  -webkit-transition: background-color .2s,border-color .2s,color .2s;
  transition: background-color .2s,border-color .2s,color .2s;
}

.pk-btn-white {
  border: 2px solid #fff9ee;
  background-color: #fff9ee;
  color: #e3212f;
}

.pk-btn-ghost-white {
  background-color: transparent;
  border: 2px solid #fff9ee;
  color: #fff9ee;
}

.pk-btn-ghost-white:hover {
  background-color: #fff9ee;
  border: 2px solid #fff9ee;
  color: #e3212f;
}

.pk-btn-cta {
  border: 2px solid #e3212f;
  background-color: #e3212f;
  color: #fff;
}

.pk-btn-cta:hover {
  border: 2px solid #cc1925;
  background-color: #cc1925;
  color: #fff;
}

.pk-btn-ghost-cta {
  background-color: transparent;
  border: 2px solid #e3212f;
  color: #e3212f;
}

.pk-btn-ghost-cta:hover {
  background-color: #cc1925;
  border: 2px solid #cc1925;
  color: #fff;
}

.pk-btn-rounded {
  border-radius: 36px;
}

.pk-btn-twin {
  max-width: 296px;
  width: 100%;
}

.pk-btn.is-small {
  font-size: 16px;
  padding: 11px 22px 12px;
  line-height: normal;
  /* border-width: 0; */
  min-width: 100px;
}

.pk-btn.is-xsmall {
  font-size: 16px;
  min-width: auto;
  padding: 10px 42px;
}

.pk-header .pk-btn-group {
  margin-top: 30px;
}


/* Sections */
.pk-pane-section {
  margin-left: auto;
  margin-right: auto;
  max-width: 595px;
}

.pk-pane-section.pk-pane-spread {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.pk-pane-img-section {
  margin-left: auto;
  margin-right: auto;
}

.pk-pane-img>img {
  width: 100%;
  height: 100%;
}

.pk-pane-text-section {
  padding-top: 40px;
}

.pk-pane-spread .pk-pane-text-section {
  padding-top: 0;
}


/* Footer */
.main-footer-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 500px;
  position: relative;
}

.main-footer-wrapper::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.525);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.main-footer {
  padding-bottom: 40px;
  padding-top: 35px;
  position: relative;
  z-index: 2;
}

.main-footer-header {
  margin-top: 25px;
}

.pk-logo {
  padding-top: 35px;
}

.pk-logo-bar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.pk-logo-image {
  width: auto;
  height: initial;
  margin: 15px 15px 15px;
}

.main-footer-separator {
  margin: 40px 0 0;
}

.main-footer-columns {
  margin-top: 25px;
}

.main-footer-menu {
  color: #fff;
  font-size: 16px;
  line-height: normal;
  margin-bottom: 30px;
}

.main-footer-menu-option {
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 30px;
}

.main-footer-menu-option:not(:last-child) {
  margin-right: 48px;
}

.main-footer-social {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.main-footer-social-icon {
  width: 26px;
  height: 26px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.main-footer-link {
  color: #fff;
  margin-right: 16px;
  display: inline-block;
}

.main-footer-link:last-child {
  margin-right: 0;
}

.main-footer-copy-text {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
}

/* More sections */

.pk-flat-box {
  position: relative;
  -webkit-transition: .2s;
  transition: .2s;
  display: block;
  height: 250px;
  width: 250px;
}

.pk-flat-box img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.pk-feature-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.pk-feature-image {
  display: none;
}

.pk-feature-copy {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.pk-feature-copy-text {
  color: #232323;
  font-size: 16px;
  line-height: 1.56;
  margin-top: 20px;
  max-width: 460px;
}

.pk-hashtags {
  margin-left: -15px;
  max-width: 460px;
  padding-top: 25px;
}

.pk-hashtags-bar {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  align-items: center;
}

.pk-hashtag {
  color: #232323;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.39;
  margin: 10px 15px;
}

.pk-ingredients-container {
  margin-top: 35px;
}

.pk-ingredient {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  flex-direction: column;
}

.pk-ingredient-img {
  width: 118px;
  height: 118px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.pk-ingredient-icon {
  width: 46px;
  height: 46px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 25px;
}

.pk-ingredient-img > img, .pk-ingredient-icon >img {
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.pk-ingredient-name {
  color: #232323;
  font-size: 16px;
  line-height: 1.56;
  margin-top: 15px;
}

.pk-hint {
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.36;
  margin-top: 16px;
}

.pk-hint.is-small {
  color: #000;
  font-size: 12px;
  font-weight: normal;
  line-height: 14px;
  margin-top: 8px;
}

.pk-social-wrapper {
  background-color: #ec1f26;
  border: 2px solid #ec1f26;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 418px;
  padding: 20px 24px 26px;
  text-align: center;
}

.pk-social-wrapper h4 {
  color: #fff;
  font-size: 18px;
  line-height: normal;
}

.pk-social {
  margin-top: 19px;
}

.pk-social-link {
  margin-right: 20px;
  display: inline-block;
}

.pk-social-link:last-child {
  margin-right: 0;
}

.pk-social-icon {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}


/* Carousel */
.BrainhubCarousel__arrows {
  background-color: rgba(255,255,255,0.01)!important;
  border: 1px solid #c9c9c9 !important;
  border-radius: 100%;
}

.BrainhubCarousel__arrows span {
  border-color: #000 !important;
  border-width: 2px 2px 0 0 !important;
  padding: 4px !important;
}

.BrainhubCarouselItem {
  -webkit-box-align: start !important;
  align-items: flex-start !important;
}


/* Tags */
.pk-tags-container {
  width: 100%;
}

.pk-tags {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.pk-tag {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.pk-tag:not(:first-child) {
  margin-left: 30px;
}

.pk-tag.is-standalone:not(:first-child) {
  margin-left: 10px;
}

.pk-tag-name {
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  margin-left: 10px;
}

.mstb-container {
  display: grid !important;
  grid-auto-flow: column !important;
  overflow: auto hidden !important;
  scroll-snap-type: x mandatory !important;
  gap: 0px !important;
  margin: 0px -24px;
  padding: 0 24px;
  scroll-padding: 0px 24px;
}

small {
  font-size: 14px;
}

.pk-pane-label {
  display: block;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

/* Media Queries */

@media (max-width: 320px) {
  .navbar-home-link {
    margin-right: 0;
  }

  .pk-btn.is-small {
    padding: 8px 12px;
  }

  .pk-header-logos .pk-logo-image, .pk-header-logos .pk-logo-link {
    width: 130px;
  }

  .pk-header-solid .pk-logo-bar .pk-logo-image:nth-child(3), .pk-header-solid .pk-logo-bar .pk-logo-link:nth-child(3) {
    width: 96px;
  }
}

@media (min-width:480px) {
  .pk-logo-image {
    margin: 20px 15px 20px;
  }
}

@media (max-width:749px) {
  .pk-ingredients {
    display: grid !important;
    grid-auto-flow: column !important;
    overflow: auto hidden !important;
    scroll-snap-type: x mandatory !important;
    gap: 24px !important;
    margin: 0px -24px;
    padding: 0 24px;
    scroll-padding: 0px 24px;
  }

  .pk-ingredients:nth-child(n) > *, .mstb-container:nth-child(n) > * {
    scroll-snap-align: start !important;
  }

  .pk-ingredients::-webkit-scrollbar {
    display: none !important;
  }

  .pk-ingredient {
    text-align: center;
  }

  .pk-ingredient-name {
    min-height: 48px;
  }

  .mstb-container > button {
    min-width: 104px !important;
  }

  .main-footer-column:not(:last-child), .main-footer-copy>span:not(:last-child) {
    margin-bottom: 16px;
    text-align: center;
  }

  .main-footer-copy-text {
    display: block;
    text-align: center;
  }

  .main-footer-wrapper {
    background-image: url('footer-bg.webp');
  }
}

@media (min-width:750px) {
  .pk-content-md {
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
    max-width: 595px;
  }

  .pk-header-copy {
    padding: 80px 20px 60px;
  }

  .pk .h1, .pk h1 {
    font-size: 48px;
    line-height: 1.08;
  }

  .pk .h2, .pk h2 {
    font-size: 30px;
    line-height: 1.2;
  }

  .pk p, .pk strong {
    font-size: 20px;
    line-height: 1.25;
  }

  .h1+p, .h2+p, .h3+p, .h4+p, .h5+p, h1+p, h2+p, h3+p, h4+p, h5+p {
    margin-top: 20px;
  }

  .pk-header-solid .pk-header-img-wrapper {
    width: 495px;
  }

  .pk-pane-text-section {
    padding-top: 50px;
  }

  .pk-pt-md-40 {
    padding-top: 40px;
  }

  .pk-pb-md-80 {
    padding-bottom: 80px;
  }

  .pk-mt-md-80 {
    margin-top: 80px;
  }

  .pk-mb-md-50 {
    margin-bottom: 50px;
  }

  .main-footer {
    padding-top: 50px;
  }

  .pk-logo-image {
    margin: 0 10px 20px;
  }

  .main-footer-link {
    margin-right: 20px;
  }

  .pk-feature {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
  }

  .pk-feature-copy {
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .pk-ingredients {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .pk-ingredients > div:last-child, .pk-flat-boxes > div {
    display: none;
  }

  .main-footer-wrapper {
    background-image: url('footer-bg@2x.webp');
  }

  .pk-tag:not(:first-child) {
    margin-left: 60px;
  }

  .pk-header .pk-btn-group {
    margin-top: 40px;
  }
}

@media (min-width:818px) {
  .pk-content.is-narrow {
    width: 770px;
  }
}

@media (max-width:969px) {
  .pk-pane-spread .pk-pane-img-section {
    padding-top: 40px;
  }
  
  .pk-flat-boxes {
    display: grid !important;
    grid-auto-flow: column !important;
    overflow: auto hidden !important;
    scroll-snap-type: x mandatory !important;
    gap: 24px !important;
    margin: 0px -24px;
    padding: 0 24px;
    scroll-padding: 0px 24px;
  }

  .pk-flat-boxes:nth-child(n) > * {
    scroll-snap-align: start !important;
  }

  .pk-flat-boxes::-webkit-scrollbar {
    display: none !important;
  }
}

@media (min-width:970px) {
  .pk-content {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pk-content-md {
    max-width: 830px;
  }

  .pk-header {
    height: 730px;
    text-align: left;
  }

  .pk-header-solid {
    height: initial;
    overflow: visible;
  }

  .pk-header-solid.is-dark {
    padding-bottom: 0;
  }

  .pk-header-content {
    display: -webkit-box;
    display: flex;
    justify-content: space-around;
    -webkit-box-align: start;
    align-items: flex-start;
    height: 100%;
  }

  .pk-header-solid .pk-header-content {
    padding-top: 100px;
  }

  .pk-header-short-img .pk-header-content {
    /* 79 + 80 */
    padding-top: 150px;
  }

  .pk-header-pattern {
    padding-left: 45px;
    padding-right: 45px;
  }

  .pk-header-copy {
    width: 50%;
    padding: 0;
  }

  p {
    margin-top: 20px;
  }

  .pk-header-img-wrapper {
    margin: 0;
    padding-bottom: 0;
    width: 456px;
  }

  .pk-header-solid .pk-header-img-wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-box-align: center;
    align-items: center;
    width: 50%;
    height: 480px;
  }

  .pk-header-img {
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .pk-header-solid .pk-header-img {
    margin-left: auto;
  }

  .pk-pane-section {
    max-width: initial;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
  }

  .pk-pane-section.pk-pane-spread {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    margin-top: 100px;
  }

  .pk-pane-img-section {
    -webkit-box-flex: 1;
    flex: 1;
  }

  .pk-pane-img-wrapper {
    height: auto;
  }

  .pk-pane-img {
    width: 100%;
    height: 100%;
  }

  .pk-pane-img>img {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .pk-pane-text-section {
    padding-top: 0;
    text-align: left;
    -webkit-box-flex: 1;
    flex: 1;
  }

  .pk-pane-text {
    max-width: 400px;
    margin-left: auto;
  }

  .pk-pane-odd .pk-pane-img-section {
    -webkit-box-ordinal-group: 3;
    order: 2;
    margin-right: 0;
  }

  .pk-pane-odd .pk-pane-text {
    margin-left: 0;
  }

  .pk-flat-boxes {
    display: grid;
    grid-template-columns: repeat(3,256px);
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .main-footer {
    padding-bottom: 60px;
  }

  .main-footer-header {
    margin-top: 40px;
  }

  .pk-logo-image {
    margin: 0 20px 20px;
  }

  .main-footer-columns {
    margin-top: 40px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .main-footer-copy {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .pk-hashtags {
    padding-top: 45px;
  }

  .pk-header-logos {
    margin-left: 0;
  }

  .pk-social-wrapper {
    margin-left: 0;
  }

  .pk-feature-image {
    display: -webkit-box;
    display: flex;
    margin: 0 auto;
    width: 289px;
    height: 289px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .pk-feature-copy-text {
    margin-top: 0;
  }

  .pk-flat-box {
    margin-bottom: 30px;
  }
}

@media (min-width:1170px) {
  .navbar-desktop {
    display: block;
  }

  .pk-content-md {
    max-width: 970px;
  }

  .pk-btn {
    padding: 14px 30px;
    font-size: 18px;
  }

  .pk-header-content {
    padding-top: 65px;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .pk-header-solid .pk-header-content {
    -webkit-box-pack: initial;
    justify-content: initial;
  }

  .pk-header-copy {
    width: 570px;
  }

  .pk-header-solid .pk-header-copy {
    width: 495px;
  }

  .pk-header-solid .pk-header-copy p {
    width: 470px;
  }

  .pk-header-img-wrapper {
    width: 570px;
  }

  .pk-header-solid .pk-header-img {

  }

  .pk-pane-text {
    max-width: 470px;
  }

  /* New */
  
  .pk-header-wrapper {
    overflow-x: visible;
  }

  .pk-header-solid {
    height: 890px;
    overflow: visible;
  }

  .pk-header-short-img .pk-header-content {
    padding-top: 199px;
  }

  .pk-header-solid .pk-header-copy {
    padding-top: 30px;
    width: 572px;
  }

  .pk-header-solid.is-dark h1 {
    font-size: 42px;
    line-height: 1.36;
  }

  .pk-header-solid .pk-header-copy p {
    width: 569px;
  }

  .pk-header-solid.is-dark p {
    font-size: 28px;
    line-height: 1.43;
    margin-top: 25px;
  }

  .pk-header-solid .pk-header-logos {
    margin-top: 80px;
    /* max-width: 382px; */
  }

  .pk-header-solid .pk-header-logos > span {
    font-size: 28px;
  }

  .pk-header-solid .pk-logo-bar .pk-logo-image, .pk-header-solid .pk-logo-bar .pk-logo-link {
    margin-bottom: 30px;
    margin-right: 20px;
    width: 129px;
  }

  .pk-header-solid .pk-logo-bar .pk-logo-image:nth-child(3), .pk-header-solid .pk-logo-bar .pk-logo-link:nth-child(3) {
    width: 100px;
  }

  .pk-header-solid .pk-logo-bar .pk-logo-image:nth-child(4), .pk-header-solid .pk-logo-bar .pk-logo-link:nth-child(4) {
    width: 127px;
  }

  .pk-header-solid .pk-header-img-wrapper {
    position: absolute;
    top: 199px;
    right: -34px;
    z-index: 101;
    height: auto;
    width: 640px;
  }

  .pk .h1, .pk h1 {
    font-size: 42px;
    line-height: 1.31;
  }

  .pk .h2, .pk h2 {
    font-size: 28px;
    line-height: 1.46;
  }

  .pk-pane-text {
    max-width: 490px;
  }

  .pk p, .pk strong {
    font-size: 21px;
    line-height: 1.52;
  }

  .pk-btn {
    padding: 22px 44px;
    font-size: 20px;
  }

  .pk-flat-boxes {
    grid-template-columns: repeat(3,360px);
  }

  .pk-flat-box {
    height: 360px;
    width: 360px;
  }

  .navbar {
    font-size: 18px;
    top: 27px;
  }

  .navbar-home-link img {
    max-width: 90px;
  }

  .list-horizontal .list-option {
    margin-right: 29px;
  }

  .pk-btn.is-small {
    font-size: 18px;
    min-width: 165px;
    padding: 14px 37px;
  }

  .pk-header-pattern {
    top: 133px;
  }

  .pk-hashtags {
    max-width: 586px;
    padding-top: 70px;
  }
  
  .pk-hashtags-bar {
    -webkit-box-pack: center;
    justify-content: center;
  }
  
  .pk-hashtag {
    font-size: 24px;
    line-height: 1.04;
    margin-bottom: 30px;
  }

}

@media screen and (min-width:1218px) {
  .pk-content, .pk-header-pattern {
    padding-left: 0;
    padding-right: 0;
    width: 1170px;
  }
}

.gmap_canvas {overflow:hidden;background:none!important;height:154px;width:300px; margin: auto;}

.mapouter{position:relative;text-align:right;height:154px;width:100%;}

.BrainhubCarousel__dots .BrainhubCarousel__dot::before {
  width: 10px!important;
  height: 10px!important;
  background-color: #D8D8D8!important;

}
.BrainhubCarousel__dots .BrainhubCarousel__dot {
  padding: 4px!important;
}
/*
li div.BrainhubCarousel__dot.BrainhubCarousel__dot--selected {
  opacity: 0.6!important;
}*/

/*.BrainhubCarousel__dots .BrainhubCarousel__dot::before {
}

.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected.BrainhubCarousel__dot::before {
  background-color: red;
}*/

.BrainhubCarousel__dots .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
  background-color: #e3212f!important;
}
.BrainhubCarousel__dots .BrainhubCarousel__dot {
  opacity: unset!important;
}

.pk-btn-transparent {
  border: 3px solid #ec1f26;
  background-color: #fff;
  color: #e3212f;
}

.pk-btn-transparent:hover {
  border: 3px solid #cc1925;
  background-color: #cc1925;
  color: #fff;
}

/* .dish-name {
  font-size: 2;
} */

.carousel-wrapper {
  max-width: 80vw;
  width: 100%;
}

@media (min-width:970px) {
  .carousel-wrapper {
    max-width: 630px;
  }
}